import { useDashboardSWR, useLocation } from '@hooks';
import { FeatureFlags } from '@types';

type UseFeatureFlagsReturn = {
  featureFlags: FeatureFlags;
  isLoading: boolean;
  isValidating: boolean;
};

export const useFeatureFlags = (): UseFeatureFlagsReturn => {
  const { instanceId } = useLocation();
  const { data, isLoading, isValidating } = useDashboardSWR<FeatureFlags>(
    `/v1/instances/${instanceId}/feature_flags`,
  );

  const featureFlags = data || {
    allow_user_create_org: false,
    allow_user_self_delete: false,
    allow_org_admin_delete: false,
    allow_bot_protection: false,
    allow_accounts_portal_page: false,
  };

  return { featureFlags, isLoading, isValidating };
};
